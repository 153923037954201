import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
    root: {
        position: 'absolute',
        left: 24,
        top: 48,
        maxWidth: 72,
        zIndex: 1,
    },
})

export default () => {
    const styles = useStyles()
    return (
        <svg
            className={styles.root}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x={0}
            y={0}
            viewBox="0 0 1911.5 1442"
            xmlSpace="preserve"
        >
            <style
                dangerouslySetInnerHTML={{
                    __html: '\n    .st0{fill:#da291c}\n  ',
                }}
            />
            <path
                className="st0"
                d="M1417.3 1092.5h-335V1195h232.5l.3 5.4c-31.3 89.2-103.1 140.7-216.4 140.7-130.8 0-229.5-104.9-229.5-237.5 0-137.9 102.7-246.7 239-246.7 104.4 0 178.8 51.2 221 142.8l96.1-44.3c-59.4-128.6-172-199.4-317.1-199.4-193.3 0-344.7 152.7-344.7 347.7 0 189.8 147.3 338.5 335.3 338.5 98.1 0 172.2-39.6 225.7-101.4l9.6 77.2h83.3v-325.5zM1417.3 537.6H887.5V368.5h468.8v-98.1H887.5V101.3h529.8V.6H779.5v637.7h637.8zM528.9 264.1h-421V.5H0v637.8h107.9V365.8h421v272.5h108.9V.5H528.9zM372.9 1316.2V881.7h264.9V780.1H0v101.6h264.9v434.5H0v101.7h637.8v-101.7zM1684.7 0h41l-63.3 173.3h-39.8L1559.1 0h42.5l41.8 121.9L1684.7 0zM1761.6 173.3V0h62.8c52.1 0 87 33.2 87 86.8s-34.9 86.5-87.2 86.5h-62.6zm61.2-36.4c26.6 0 48.4-15.4 48.4-50.1s-21.7-50.3-48.4-50.3h-22.5v100.4h22.5z"
            />
        </svg>
    )
}
