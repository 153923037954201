import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import DropZone from './DropZone'
import Status from './Status'
import Logo from './Logo'
import EventEmitter from 'events'
import Auth from './Auth'
import queryString from 'query-string'
import CatapultMessage from './CatapultMessage'

const useStyles = makeStyles({
    root: {
        display: 'grid',
        gridTemplateColumns: 'auto',
        gridTemplateRows: 'auto 200px',
        gridTemplateAreas: "'drop-zone' 'status' 'meta' 'actions'",
    },
})

const closeMessage = ({ setShowCatapultMessage }) => () =>
    setShowCatapultMessage(false)

const ee = new EventEmitter()

const Upload = () => {
    const styles = useStyles()
    const { authenticated } = Auth.useContainer()
    const [showCatapultMessage, setShowCatapultMessage] = useState(false)
    useEffect(() => {
        const { ref } = queryString.parse(window.location.search)
        if (ref === 'catapult') {
            setShowCatapultMessage(true)
        }
    }, [])
    return authenticated ? (
        <>
            <Logo />
            <div className={styles.root}>
                {showCatapultMessage && (
                    <CatapultMessage
                        closeMessage={closeMessage({ setShowCatapultMessage })}
                    />
                )}
                <DropZone ee={ee} />
                <Status ee={ee} />
            </div>
        </>
    ) : null
}

export default () => (
    <Auth.Provider>
        <Upload />
    </Auth.Provider>
)
