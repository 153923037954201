import React from 'react'
import Upload from './Upload'
import { Router } from '@reach/router'
import Fetch from './Fetch'
import { useInternetExplorer, InternetExplorer } from './InternetExplorer'

const App = () => {
    const isIE = useInternetExplorer()
    if (isIE) return <InternetExplorer />
    return (
        <Router>
            <Upload path="/" />
            <Fetch path=":fileId" />
        </Router>
    )
}

export default App
