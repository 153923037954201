import React, { useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import colors from './colors'
import { useMachine } from 'use-machine'
import { machineConfig, machineOptions } from './stateMachine'
import { head } from 'ramda'
import { uploadFile } from './transfer'
import animations from './animations'
import uuid from 'uuid/v1'
import { Button } from '@material-ui/core'
import Auth from './Auth'

const MorphSVGPlugin = window.MorphSVGPlugin // eslint-disable-line no-unused-vars

const useStyles = makeStyles({
    root: {
        maxWidth: 300,
        maxHeight: 300,
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    svg: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    hidden: {
        visibility: 'hidden',
    },
    file: {
        display: 'none',
    },
    restart: {
        position: 'absolute',
        zIndex: 1,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        transform: 'translate(0, -64px)',
    },
    '@media (max-height: 680px)': {
        restart: {
            transform: 'translate(0, 0)',
        },
    },
})

const onDragEnter = machine => () => {
    if (machine.state.value === 'uploaded') return

    machine.send('valid')
}

const onDragLeave = machine => () => {
    if (machine.state.value === 'uploaded') return

    machine.send('wait')
}

const onClick = ({ file, machine }) => () => {
    if (machine.state.value === 'waiting') {
        file.current.click()
    }
}

const onDrop = ({ machine, ee, user, token }) => async e => {
    if (machine.state.value === 'uploaded') return

    upload({ machine, ee, user, file: head(e.dataTransfer.files), token })
}

const onChange = ({ machine, ee, user, file, token }) => e => {
    machine.send('valid')
    upload({ machine, ee, user, file: head(file.current.files), token })
}

const upload = async ({ machine, ee, user, file, token }) => {
    const { name, size } = file

    if (size > process.env.REACT_APP_MAX_FILE_SIZE) {
        machine.send('invalid')
        return
    }

    machine.send('upload')

    const fileId = uuid()

    const expiration = await uploadFile({
        uploader: user,
        file,
        fileId,
        onProgress: val => {
            animations.uploadAt(val)
            if (val === 100) {
                animations.uploadedWaiting()
            }
        },
        token,
    })

    machine.send('uploaded')

    ee.emit('UPLOADED', {
        name,
        size,
        fileId,
        expiration,
    })
}

const restartClick = ({ machine, ee }) => () => {
    machine.send('wait')
    ee.emit('RESTART')
}

export default ({ ee }) => {
    const styles = useStyles()

    const machine = useMachine(machineConfig, machineOptions(() => machine))
    const file = useRef()
    const { user, token } = Auth.useContainer()

    useEffect(() => {
        document.addEventListener('dragover', e => e.preventDefault(), false)
        document.addEventListener('drop', e => e.preventDefault(), false)
        machine.send('start')
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className={styles.root}>
            {machine.state.value === 'uploaded' && (
                <div
                    className={styles.restart}
                    onClick={restartClick({ machine, ee })}
                >
                    <Button>Recommencer</Button>
                </div>
            )}
            <svg viewBox="0 0 500 500" className={styles.svg}>
                <path
                    id="membrane"
                    fill={colors.blue}
                    d="M250,168c45.29,0,82,36.71,82,82s-36.71,82-82,82s-82-36.71-82-82S204.71,168,250,168z"
                    onDragEnter={onDragEnter(machine)}
                    onDragLeave={onDragLeave(machine)}
                    onDrop={onDrop({ machine, ee, user, token })}
                    onClick={onClick({ file, machine })}
                />
                <path
                    id="membrane-ok"
                    className={styles.hidden}
                    d="M250,78c94.99,0,172,77.01,172,172s-77.01,172-172,172S78,344.99,78,250S155.01,78,250,78z"
                />
                <path
                    id="membrane-ko"
                    className={styles.hidden}
                    d="M80,250l85-147.22h170L420,250l-85,147.22H165L80,250z"
                />
                <path
                    id="membrane3"
                    className={styles.hidden}
                    d="M48.02,145.6C82.74,75.78,180.7,35.73,258.54,42.8c83.64,7.6,172.31,71.37,202.6,149.47c24.49,63.05,10.92,153.83-39.49,198.99c-79.46,71.28-233.83,92.58-319.38,28.68C27.48,364,6.49,229.1,48.02,145.6z"
                />
                <path
                    id="membrane2"
                    className={styles.hidden}
                    d="M129.73,69.39c93.7-51.4,247.4-70.72,320.6,7.17c42.5,45.32-5.1,124.22-7.5,186.32c-1.8,47.7,28.4,109.2-5.2,143.1c-85.3,86-269.4,93.3-363.08,16.5c-54.53-44.7-45.86-139.9-32.11-209C53.39,158.38,80.54,96.34,129.73,69.39z"
                />
                <path
                    id="membrane1"
                    className={styles.hidden}
                    d="M163.39,60.43c69.4-49.7,142.7-46.03,253.4,37.5c51.3,38.71,17.9,119.21,45.6,187.31c21,51.7-10.3,150.9-78.9,147.5c-94-4.7-208.7,76.8-281.83,17.6c-78.24-63.5-88.95-186.3-44.02-299C76.12,105.04,131.69,83.1,163.39,60.43z"
                />

                <path
                    id="nucleus"
                    fill={colors.grey}
                    pointerEvents="none"
                    d="M250,208.5c22.92,0,41.5,18.58,41.5,41.5s-18.58,41.5-41.5,41.5s-41.5-18.58-41.5-41.5S227.08,208.5,250,208.5z"
                />
                <path
                    id="nucleus4"
                    className={styles.hidden}
                    d="M400.69,348.46c-69.06,50.12-190.82,58.12-255.74,2.8c-60.57-51.64-103.07-194.42-35.42-236.31c38.77-23.98,72.31,55.88,111.83,78.58c63.79,36.61,171.58,24.22,200.73,91.85C430.87,305.77,418.65,335.43,400.69,348.46z"
                />
                <path
                    id="nucleus3"
                    className={styles.hidden}
                    d="M259.13,351.6c-44.49,15.21-83.99,79.85-127.07,60.98c-56.41-24.64-66.9-115.96-54.3-176.16c11.25-53.81,56.24-105.46,107.76-124.97c62.91-23.68,198.81-58.57,199.82,27.57c0.94,86.09,65.64,128.44,31.92,178.58C387.14,362.44,310.1,334.13,259.13,351.6z"
                />
                <path
                    id="nucleus2"
                    className={styles.hidden}
                    d="M112.4,344.4c-27.69-69.16-29.15-177.43,30.89-221.45c64.62-47.38,191.11-25.56,236.84,40.29c33.66,48.5,45.25,183.02-32.9,174.18c-78.15-8.91-104.7,72.96-162.3,64.46C154.45,397.39,123.87,373.07,112.4,344.4z"
                />
                <path
                    id="nucleus1"
                    className={styles.hidden}
                    d="M119.12,118.8c52.25-55.61,171.02-45.93,228.97,3.48c62.08,53.01,95.55,180.6,40.77,240.94c-68.34,75.36-294.26,80.08-304.65-21.14c-6.78-65.93,37.29-79.86,42.84-122.88C131.35,185.89,96.13,143.24,119.12,118.8z"
                />
                <path
                    id="nucleus-ok"
                    className={styles.hidden}
                    d="M250,155.5c52.19,0,94.5,42.31,94.5,94.5s-42.31,94.5-94.5,94.5s-94.5-42.31-94.5-94.5S197.81,155.5,250,155.5z"
                />
                <path
                    id="nucleus-ko"
                    className={styles.hidden}
                    d="M151.5,250l49.25-85.3h98.5L348.5,250l-49.25,85.3h-98.5L151.5,250z"
                />
                <path
                    id="upload"
                    className={styles.hidden}
                    fill={colors.grey}
                    d="M250,137.39c62.19,0,112.61,50.42,112.61,112.61S312.19,362.61,250,362.61S137.39,312.19,137.39,250S187.81,137.39,250,137.39z"
                />

                <path
                    id="check"
                    className={styles.hidden}
                    fill="#B620E0"
                    d="M149 276.81 C176 314.11 203 351.41 230 388.71 270.05 304.81 310.1 220.9 350.16 137 306.98 196.74 263.8 256.49 220.62 316.24 196.75 303.1 172.87 289.95 149 276.81 z"
                />
                <path
                    id="uploadWait"
                    className={styles.hidden}
                    d="M250,56.01c107.14,0,193.99,86.85,193.99,193.99S357.14,443.99,250,443.99S56.01,357.14,56.01,250S142.86,56.01,250,56.01z"
                />
            </svg>
            <input
                className={styles.file}
                type="file"
                ref={file}
                onChange={onChange({ machine, ee, file, user, token })}
            />
        </div>
    )
}
