import 'formdata-polyfill'
import axios from 'axios'

export const uploadFile = async ({
    file,
    fileId,
    uploader,
    onProgress,
    token,
}) => {
    const { name, size } = file

    const formData = new FormData()
    formData.set('fileId', fileId)
    formData.set('fileName', name)
    formData.set('fileSize', size)
    formData.set('uploader', uploader)
    formData.append('file', file)

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${await token()}`,
        },
        onUploadProgress: progressEvent => {
            const val = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
            )
            onProgress(val)
        },
    }

    const {
        data: { expiration },
    } = await axios.post(
        `${process.env.REACT_APP_API_URI}/create`,
        formData,
        config
    )

    return expiration
}
