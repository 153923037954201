import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
    catapultMessage: {
        position: 'absolute',
        top: 32,
        left: 128,
        width: 'calc(100vw - 256px)',
        padding: '8px 16px',
        background: '#ddd',
        borderRadius: 4,
        zIndex: 1,
    },
    close: {
        position: 'absolute',
        right: 16,
        top: 16,
        width: 24,
        height: 24,
    },
})

export default ({ closeMessage }) => {
    const styles = useStyles()
    return (
        <div className={styles.catapultMessage}>
            <div className={styles.close} onClick={closeMessage}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                    <path d="M0 0h24v24H0z" fill="none" />
                </svg>
            </div>
            <p>Le service Catapult tire sa révérence...</p>
            <p>
                Désormais, l'application TelePod (https://telepod.heig-vd.ch) le
                remplace.
            </p>
            <p>
                L'accès aux fichiers actuellement partagés par Catapult reste
                possible jusqu'à la fin de leur période de validité.
            </p>
        </div>
    )
}
