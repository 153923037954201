import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Logo from './Logo'
import axios from 'axios'
import colors from './colors'
import { Button } from '@material-ui/core'
import { formatDate, formatFileSize } from './format'
import clsx from 'clsx'
import { createWriteStream } from 'streamsaver'

const useStyles = makeStyles({
    root: {
        background: colors.grey,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100vw',
        height: '100vh',
        color: colors.lightGrey,
    },
    fileName: {
        fontSize: 24,
    },
    button: {
        marginTop: 48,
        fontSize: 21,
        color: colors.grey,
        padding: '16px 24px',
        background: `linear-gradient(30deg, ${colors.pink} 40%, ${colors.blue} 90%)`,
        border: 'none',
        borderRadius: 12,
        outline: 'none',
    },
    expired: {
        color: colors.pink,
    },
})

const download = ({ fileId, fileName, fileSize }) => {
    const url = `${process.env.REACT_APP_API_URI}/download/${fileId}`
    fetch(url).then(res => {
        const fileStream = createWriteStream(fileName, { size: fileSize })
        const readableStream = res.body

        if (window.WritableStream && readableStream.pipeTo) {
            return readableStream.pipeTo(fileStream)
        }
        const writer = fileStream.getWriter()
        const reader = res.body.getReader()
        const pump = () =>
            reader
                .read()
                .then(res =>
                    res.done
                        ? writer.close()
                        : writer.write(res.value).then(pump)
                )
        pump()
    })
}

const loadData = async ({ setUpload, fileId }) => {
    const { data } = await axios.get(
        `${process.env.REACT_APP_API_URI}/fetch/${fileId}`
    )
    setUpload(data)
}

const onClick = ({ setDownloading, upload }) => e => {
    setDownloading(true)
    download(upload)
}

const Fetch = ({ fileId }) => {
    const styles = useStyles()
    const [upload, setUpload] = useState()
    const [downloading, setDownloading] = useState(false)
    useEffect(() => {
        loadData({ setUpload, fileId })
    }, [fileId])
    return (
        <div className={styles.root}>
            <Logo />
            {upload && (
                <>
                    <p>
                        <span className={styles.fileName}>
                            {upload.fileName}
                        </span>{' '}
                        <span className={styles.fileSize}>
                            ({formatFileSize(upload.fileSize)})
                        </span>
                    </p>
                    <p>
                        <span>Transmis par {upload.uploader}.</span>{' '}
                        <span
                            className={clsx({
                                [styles.expired]: upload.isExpired,
                            })}
                        >
                            Expiration le {formatDate(upload.expiration)}
                        </span>
                    </p>
                    <Button
                        variant="contained"
                        disabled={downloading || upload.isExpired}
                        className={styles.button}
                        onClick={onClick({ setDownloading, upload })}
                    >
                        Télécharger
                    </Button>
                </>
            )}
        </div>
    )
}

export default Fetch
