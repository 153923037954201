import { useState, useEffect } from 'react'
import Keycloak from 'keycloak-js'
import { createContainer } from 'unstated-next'
const { REACT_APP_KEYCLOAK_CONFIG } = process.env

const getToken = keycloak => () =>
    new Promise((resolve, reject) =>
        keycloak
            .updateToken(20)
            .success(() => resolve(keycloak.token))
            .error(() => reject('Failed to refresh token'))
    )

export function useAuth() {
    const [auth, setAuth] = useState({ authenticated: false })

    useEffect(() => {
        const keycloak = Keycloak(REACT_APP_KEYCLOAK_CONFIG)
        keycloak
            .init({ onLoad: 'login-required' })
            .success(authenticated => {
                authenticated &&
                    keycloak.loadUserProfile().success(profile => {
                        setAuth({
                            user: profile.username,
                            token: getToken(keycloak),
                            authenticated,
                        })
                    })
            })
            .error(console.error)
    }, [])

    return auth
}

export default createContainer(useAuth)
