import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import colors from './colors'
import clsx from 'clsx'
import { TweenLite } from 'gsap/TweenMax'
import Copy from './Copy'
import ClipboardJS from 'clipboard'
import './tooltip.css'
import Tooltip from 'tooltip.js'
import { formatDate, formatFileSize } from './format'

const useStyles = makeStyles({
    root: {
        boxSizing: 'border-box',
        gridArea: 'status',
        background: colors.black,
        position: 'relative',
    },
    element: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        padding: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        color: colors.lightGrey,
    },
    done: {
        background: colors.lightGrey,
        color: colors.black,
        transform: 'matrix(0, 0, 0, 1, 0, 0)',
    },
    fileSize: {
        color: colors.grey,
    },
    copy: {
        width: 16,
        marginLeft: 8,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    copyWrapper: {
        display: 'inline-block',
    },
})

const uploaded = setUpload => upload => {
    setUpload(upload)
    TweenLite.fromTo(
        '.done',
        0.8,
        { opacity: 0, scaleX: 0, transformOrigin: '0% 100%' },
        { opacity: 1, scaleX: 1 },
        0
    )
}

const getSharingUrl = fileId => `${process.env.REACT_APP_APP_URI}/${fileId}`

const onClick = fileId => () => {
    console.log(getSharingUrl(fileId))
    var clipboard = new ClipboardJS('.btn')
    clipboard.destroy()
}

const hookClipboard = () => {
    const clipboard = new ClipboardJS('[data-clipboard]')
    clipboard.on('success', function(e) {
        const t = new Tooltip(e.trigger, {
            placement: 'bottom',
            title: 'Copié !',
            trigger: 'click',
        })
        t.show()
        e.trigger.addEventListener('mouseout', () => t.dispose())
    })
}

const restart = () => {
    TweenLite.fromTo(
        '.done',
        0.8,
        { opacity: 1, scaleX: 1, transformOrigin: '100% 0%' },
        { opacity: 0, scaleX: 0 }
    )
}

export default ({ ee }) => {
    const styles = useStyles()
    const [upload, setUpload] = useState()
    useEffect(() => {
        ee.on('UPLOADED', uploaded(setUpload))

        ee.on('RESTART', restart)

        hookClipboard()
    }, [ee])
    return (
        <div className={styles.root}>
            <div className={styles.element}>
                <p>Déposez votre fichier sur la forme.</p>
                <p>
                    Vous pouvez aussi cliquer sur cette dernière pour choisir un
                    fichier.
                </p>
                <p>
                    Taille maximale pour l'upload: 2 Gibibytes. Expiration après
                    une semaine.
                </p>
            </div>
            {upload && (
                <div className={clsx('done', styles.element, styles.done)}>
                    <p>
                        {upload.name}{' '}
                        <span className={styles.fileSize}>
                            ({formatFileSize(upload.size)})
                        </span>
                    </p>
                    <p>
                        {getSharingUrl(upload.fileId)}
                        <span
                            className={styles.copyWrapper}
                            onClick={onClick(upload.fileId)}
                            data-clipboard
                            data-clipboard-text={getSharingUrl(upload.fileId)}
                        >
                            <Copy className={styles.copy} />
                        </span>
                    </p>
                    <p>Expiration le {formatDate(upload.expiration)}</p>
                </div>
            )}
        </div>
    )
}
