import animations from './animations'

export const machineConfig = {
    initial: 'initial',
    states: {
        initial: {
            on: { start: { target: 'starting', actions: 'start' } },
        },
        starting: {
            on: { wait: { target: 'waiting', actions: 'enterWait' } },
        },
        waiting: {
            on: {
                valid: { target: 'valid', actions: 'enterValid' },
            },
        },
        valid: {
            on: {
                wait: { target: 'waiting', actions: 'enterWait' },
                upload: { target: 'uploading', actions: 'enterUpload' },
                invalid: { target: 'invalid', actions: 'enterInvalid' },
            },
        },
        invalid: {
            // on: {
            //     wait: { target: 'waiting', actions: 'enterWait' },
            // },
        },
        uploading: {
            on: { uploaded: { target: 'uploaded', actions: 'enterUploaded' } },
        },
        uploaded: {
            on: { wait: { target: 'waiting', actions: 'enterWait' } },
        },
    },
}

export const machineOptions = machine => ({
    actions: {
        start: () => {
            animations.start(machine())
        },
        enterWait: () => {
            animations.wait()
        },
        enterValid: () => {
            animations.valid()
        },
        enterInvalid: () => {
            animations.invalid()
        },
        enterUpload: () => {
            animations.upload()
        },
        enterUploaded: () => {
            animations.uploaded()
        },
    },
})
