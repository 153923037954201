import { Elastic, Sine, TweenLite, Bounce } from 'gsap/TweenMax'
import Chance from 'chance'
import { values } from 'ramda'
import colors from './colors'

const chance = new Chance()

const membraneMorphs = ['membrane1', 'membrane2', 'membrane3']
const nucleusMorphs = ['nucleus1', 'nucleus2', 'nucleus3', 'nucleus4']

const pickFutureMorph = all => {
    return all[chance.integer({ min: 0, max: all.length - 1 })]
}

const getFutureMorph = ({ morphs, current }) => {
    let future
    do {
        future = pickFutureMorph(morphs)
    } while (future === current)
    return future
}

const id = name => `#${name}`

const running = {
    membrane: undefined,
    nucleus: undefined,
}

const waitLoop = ({ morphs, current, target }) => {
    const future = getFutureMorph({ morphs, current })
    const duration = chance.integer({ min: 2, max: 7 })
    running[target] = TweenLite.to(id(target), duration, {
        morphSVG: id(future),
        ease: Sine.easeInOut,
        fill: target === 'nucleus' ? colors.grey : colors.blue,
        onComplete: waitLoop,
        onCompleteParams: [{ morphs, current: future, target }],
    })
}

const start = machine => {
    const membrane = getFutureMorph({ morphs: membraneMorphs })
    TweenLite.to(id('membrane'), 1, {
        morphSVG: id(membrane),
        ease: Sine.easeOut,
    })
    const nucleus = getFutureMorph({ morphs: nucleusMorphs })
    TweenLite.to(id('nucleus'), 2, {
        morphSVG: id(nucleus),
        ease: Bounce.easeOut,
        onComplete: () => machine.send('wait'),
    })
}

const stopRunningAnimations = () => values(running).map(x => x.kill())

const wait = () => {
    waitLoop({ morphs: membraneMorphs, target: 'membrane' })
    waitLoop({ morphs: nucleusMorphs, target: 'nucleus' })
}

const valid = () => {
    stopRunningAnimations()
    TweenLite.to(id('membrane'), 0.5, {
        morphSVG: id('membrane-ok'),
        ease: Sine.easeInOut,
        fill: colors.pink,
    })
    TweenLite.to(id('nucleus'), 0.5, {
        morphSVG: id('nucleus-ok'),
        ease: Sine.easeInOut,
        fill: colors.grey,
    })
}

const invalid = () => {
    TweenLite.to(id('membrane'), 1, {
        morphSVG: id('membrane-ko'),
        ease: Elastic.easeInOut,
        fill: colors.red,
    })
    TweenLite.to(id('nucleus'), 1.2, {
        morphSVG: id('nucleus-ko'),
        ease: Elastic.easeInOut,
        fill: colors.grey,
    })
}

const upload = () => {
    TweenLite.to(id('upload'), 1, {
        ease: Sine.easeOut,
        stroke: colors.pink,
        strokeWidth: 70,
        strokeDasharray: 708,
        strokeDashoffset: 708,
        autoAlpha: 1,
    })

    TweenLite.to(id('membrane'), 1, {
        fill: colors.lightGrey,
    })
}

const uploadedWaiting = () => {
    TweenLite.to(id('membrane'), 0.5, {
        morphSVG: id('uploadWait'),
        ease: Sine.easeInOut,
        repeat: 10,
    })
    TweenLite.to(id('membrane'), 0.5, {
        morphSVG: id('membrane-ok'),
        ease: Bounce.easeInOut,
        delay: 0.5,
        repeat: 10,
    })
}

const uploadAt = val => {
    const x = (val * 708) / 100
    TweenLite.to(id('upload'), 1, {
        ease: Elastic.easeOut,
        strokeDashoffset: 708 - x,
    })
}

const uploaded = () => {
    TweenLite.to(id('upload'), 0.5, {
        autoAlpha: 0,
    })
    TweenLite.to(id('membrane'), 2, {
        morphSVG: id('membrane-ok'),
        ease: Sine.easeOut,
        fill: colors.pink,
    })
    TweenLite.to(id('nucleus'), 1, {
        morphSVG: id('check'),
        ease: Bounce.easeOut,
        fill: colors.grey,
    })
}

export default {
    start,
    wait,
    valid,
    invalid,
    upload,
    uploadAt,
    uploaded,
    uploadedWaiting,
}
