import React from 'react'
import Protozoa from './Protozoa'
import { makeStyles } from '@material-ui/styles'
import colors from './colors'

const useStyles = makeStyles({
    root: {
        background: colors.grey,
        height: 'calc(100vh - 200px)',
        gridArea: 'drop-zone',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 100,
    },
})

export default ({ ee }) => {
    const styles = useStyles()
    return (
        <div className={styles.root}>
            <Protozoa ee={ee} />
        </div>
    )
}
